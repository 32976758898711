
.itm-btn{
   @apply relative leading-normal py-1.5 px-3 border rounded-md font-medium focus:outline-none disabled:opacity-50 text-center
}
.itm-btn--spaced{
   @apply mr-2 last:mr-0
}
.itm-btn--animated{
   @apply relative transition-all
}
.itm-btn--withArrow{
   @apply pr-[1.5em]
}
.itm-btn--circle{
   @apply relative leading-normal p-0 border rounded-full font-medium focus:outline-none disabled:opacity-50
}
.itm-btn-bs--primary{
   @apply border-primary-border text-primary-a11y hover:bg-primary-10 bg-primary
}
.itm-btn-bs--primary-outline{
   @apply border-primary-outline text-primary-outline bg-transparent hover:bg-primary-outline-hover hover:text-primary-outline-hover-a11y
}
.itm-btn-bs--success{
   @apply border-green-600 text-white bg-green-600 hover:bg-green-700 hover:border-green-700
}
.itm-btn-bs--success-outline{
   @apply border-green-700 text-green-700 bg-transparent hover:bg-green-700 hover:text-white
}
.itm-btn-bs--warning{
   @apply border-amber-400 text-white bg-amber-400 hover:bg-amber-500 hover:border-amber-500
}
.itm-btn-bs--danger{
   @apply border-red-600 text-white bg-red-600 hover:bg-red-700 hover:border-red-700
}
.itm-btn-bs--default{
   @apply border-gray-300 text-zinc-700 bg-white hover:bg-gray-100
}
.itm-btn-bs--gray{
   @apply border-gray-300 text-gray-700 bg-gray-100 hover:bg-gray-200
}

.itm-btn-arrow{
   @apply absolute top-1/2 right-[1em] -my-[0.7em] -mx-[0.3em]
}
.itm-btn-arrow--invisible{
   @apply invisible
}
.itm-btn-arrow--loading{
   @apply absolute top-1/2 right-[1em] -m-[0.7em]
}
.itm-btn-group :not(.itm-btn--spaced):not(:last-child){
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}
.itm-btn-group :not(.itm-btn--spaced):not(:first-child){
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
}
.itm-btn-group :not(.itm-btn--spaced):not(:first-child){
   margin-left: -1px;
}
