.itm-phone-select {
    width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    color: transparent;
    border: none;
    background: 0 0;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
 }