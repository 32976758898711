.popover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1060;
      display: none;
      max-width: 276px;
      padding: 1px;
      font-size: 14px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 6px;
      -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      box-shadow: 0 5px 10px rgba(0,0,0,0.2);
 }

.alerts-container--reverse {
   display: flex;
   flex-direction: column-reverse;
}

.alerts-container,
.alerts-container--reverse {
   position: fixed;
   z-index: 1030;
   bottom: 0;
   right: 0;
   width: 350px;
   overflow: hidden;
}
.alerts-container.has-alerts,
.alerts-container--reverse.has-alerts{
   padding: 20px;
}

.alert-default-enter {
   opacity: 0.01;
   margin-left: 400px;
   margin-right: -400px;
}

.alert-default-enter.alert-default-enter-active {
   opacity: 1;
   margin-left: 0;
   margin-right: 0;
   transition: opacity 500ms ease-in, margin 230ms ease-out;
}

.alert-default-exit {
   opacity: 1;
   margin-left: 0;
   margin-right: 0;
}

.alert-default-exit.alert-default-exit-active {
   opacity: 0.01;
   margin-left: 400px;
   margin-right: -400px;
   transition: opacity 500ms ease-in, margin 230ms ease-out;
}

.alert-popover.text-default .alert-icon {
   color: #ffc107;
}

.alert-popover {
   position: relative;
   display: block;
   padding: 15px;
   margin-bottom: 15px;
   max-width: 350px;
}

.alert-popover .popover-body:after {
   content: '';
   display: block;
   clear: both;
}

.alert-popover .close {
   position: absolute;
   top: 4px;
   right: 8px;
}

.alert-popover .alert-icon {
   margin-right: 15px;
   font-size: 24px;
}

.alert-popover .alert-text {
   font-size: 14px;
   max-width: 225px;
}