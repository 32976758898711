
@keyframes placeHolderShimmer{
   0%{
       background-position: -468px 0
   }
   100%{
       background-position: 468px 0
   }
}

.itm-line-spinner{
   animation-duration: 1s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #f6f7f8;
   background: linear-gradient(90deg,#eee 8%,#ddd 18%,#eee 33%);
   background-size: 800px 104px;
}
