.itm-alert a:not(.itm-btn){
   color: inherit;
   text-decoration: underline;
}
.itm-alert .itm-media-left{
   color: white
}

.itm-alert.itm-alert--success .itm-media-left{
   background-color: rgb(22, 101, 52);
   color: white;
}
.itm-alert.itm-alert--success .itm-media-body{
   background-color: rgb(21, 128, 61);
   color: white;
}
.itm-alert.itm-alert--light.itm-alert--success .itm-media-body,
.itm-alert.itm-alert--light.itm-alert--success .itm-media-left{
   border-color: #d6e9c6;
   background-color: #dff0d8;
   color: #3c763d;
}

.itm-alert.itm-alert--danger .itm-media-left{
   background-color: #a94442;
}
.itm-alert.itm-alert--danger .itm-media-body{
   background-color: #c0605e;
   color: white;
}
.itm-alert.itm-alert--light.itm-alert--danger .itm-media-body,
.itm-alert.itm-alert--light.itm-alert--danger .itm-media-left{
   border-color: #ebccd1;
   background-color: #f2dede;
   color: #a94442;
}

.itm-alert.itm-alert--warning .itm-media-left{
   background-color: #8a6d3b;
}
.itm-alert.itm-alert--warning .itm-media-body{
   background-color: #ae894a;
   color: white;
}
.itm-alert.itm-alert--light.itm-alert--warning .itm-media-body,
.itm-alert.itm-alert--light.itm-alert--warning .itm-media-left{
   border-color: #faebcc;
   color: #8a6d3b;
   background-color: #fcf8e3;
}

.itm-alert.itm-alert--default .itm-media-left{
   background-color: #cccccc;
   color: #333333;
}
.itm-alert.itm-alert--default .itm-media-body{
   background-color: #efefef;
   color: #333333;
}
.itm-alert.itm-alert--light.itm-alert--default .itm-media-body,
.itm-alert.itm-alert--light.itm-alert--default .itm-media-left{
   border-color: #ccc;
   color: #333333;
   background-color: #efefef;
}

.itm-alert.itm-alert--info .itm-media-left{
   background-color: #31708f;
}
.itm-alert.itm-alert--info .itm-media-body{
   background-color: #31708f;
   color: white;
}
.itm-alert.itm-alert--light.itm-alert--info .itm-media-body,
.itm-alert.itm-alert--light.itm-alert--info .itm-media-left{
   border-color: #bce8f1;
   color: #31708f;
   background-color: #d9edf7;
}


.itm-alert.itm-alert--spinning .itm-media-left {
   background-color: #ccc;
   color: #ccc;
}
.itm-alert.itm-alert--spinning .itm-media-body {
   background-color: #fafafa;
}