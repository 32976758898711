.react-switch-checkbox {
   height: 0;
   width: 0;
   visibility: hidden;
}

.react-switch-label {
   position: relative;
   padding-left: 3em;
}

.react-switch-label::before {
   content: '';
   display: block;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
   width: 2.5em;
   height: 1.25em;
   background: #FFF;
   border-radius: 10px;
   position: absolute;
   left: 0;
   transition: background-color .2s;
   border: 1px solid #adb5bd;
}

.react-switch-label::after {
   content: '';
   position: absolute;
   top: 2px;
   left: 2px;
   width: 1em;
   height: 1em;
   border-radius: 10px;
   transition: 0.2s;
   background: #adb5bd;
   box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label::before {
   background-color: #28a745;
   border: 1px solid #28a745;
}

.react-switch-checkbox:checked+.react-switch-label::after {
   left: calc(2.5em - 2px);
   transform: translateX(-100%);
   background: #FFF;
}


.react-switch-label:active::after {
   width: 1.25em;
}