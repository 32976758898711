.form-control {
   display: block;
   width: 100%;
   height: calc(1.5em + .75rem + 2px);
   padding: .375rem .75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: .25rem;
   -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
   outline: 0;
 }

 .form-control:disabled{
   cursor: not-allowed;
   background-color: #efefef
 }
 
 .form-control:focus {
   border-color: #66afe9;
   box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
 }
 
 textarea.form-control {
   height: auto;
 }
 
 .form-check {
   display: block;
   min-height: 1.5rem;
   padding-left: 1.5em;
 }
 .form-check label{
    cursor: pointer;
 }
 
 .form-check-input {
   border-radius: .25em;
   width: 1em;
   height: 1em;
   margin-top: .25em;
   vertical-align: top;
   background-color: #fff;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   border: 1px solid rgba(0, 0, 0, 0.25);
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   -webkit-print-color-adjust: exact;
   print-color-adjust: exact;
 }
 
 .form-check-input:checked {
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
   background-color: #0d6efd;
   border-color: #0d6efd;
 }
 .form-check-input:focus{
   outline: 5px auto -webkit-focus-ring-color;
   outline-offset: -1px;
 }
 
 .form-check .form-check-input {
   float: left;
   margin-left: -1.5em;
 }
 
 .form-input-error {
   border-color: #f87171 !important;
   outline-color: #f87171 !important;
 }
 .form-input-success {
  border-color: #198754 !important;
  outline-color: #198754 !important;
}
 
 .form-toggle {
   display: block;
   min-height: 1.5rem;
 }
 
 .form-toggle label {
   display: inline-block;
   max-width: 100%;
   margin-bottom: 5px;
 }
 
 .form-toggle label input {
   opacity: 0;
   position: absolute;
   z-index: -1;
 }
 
 .form-toggle label .bar-toggle {
   position: relative;
   display: inline-block;
   width: 50px;
   height: 24px;
   background-color: #9e9e9e;
   border: 0;
   border-radius: 15px;
   margin-right: 8px;
   vertical-align: middle;
   -webkit-transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s;
   transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s;
 }
 
 .form-toggle label .bar-toggle::after {
   content: "";
   position: absolute;
   background-color: #fff;
   top: 2px;
   left: 2px;
   height: 20px;
   width: 20px;
   border: 0;
   border-radius: 400px;
   -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
   transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
 }
 
 .form-toggle label input:checked + span {
   background-color: rgba(3, 169, 244, 0.5) !important;
 }
 
 .form-toggle label input:checked + span::after {
   right: 2px;
   left: auto;
 }
 
 .input-group {
   position: relative;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -ms-flex-wrap: wrap;
       flex-wrap: wrap;
   -webkit-box-align: stretch;
       -ms-flex-align: stretch;
           align-items: stretch;
   width: 100%;
 }
 
 .input-group input {
   position: relative;
   -webkit-box-flex: 1;
       -ms-flex: 1 1 auto;
           flex: 1 1 auto;
   width: 1%;
   min-width: 0;
 }
 
 .input-group .input-group-text {
   position: relative;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
       -ms-flex-align: center;
           align-items: center;
   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #212529;
   text-align: center;
   white-space: nowrap;
   background-color: #e9ecef;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
 }
 .has-error .input-group .input-group-text.has-error{
  color: rgb(220 38 38);
  background-color: #f2dede;
  border-color: #f87171 !important;
 }
 
 .has-success .input-group .input-group-text{
  color: #198754;
  background-color: #dff0d8;
  border-color: #198754;
 }

 .has-success .form-control{
  border-color: #3c763d;
 }

 .has-success .form-control:focus{
   border-color: #2b542c;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
 }

 .has-error .form-control{
  border-color: #a94442;
 }

 .has-error .form-control:focus{
   border-color: #843534;
   box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
 }

 .has-success label{
  color: #3c763d
 }

 .has-error label, .has-error .error-block{
  color: #a94442
 }
 
 .input-group :first-child {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 
 .input-group :nth-child(2) {
   margin-left: -1px;
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }
 /*# sourceMappingURL=form.css.map */