@tailwind base;
@tailwind components;

@layer base {
   html {
      @apply h-full;
      font-size: 14px;
   }
   body {
     @apply flex min-h-full bg-white antialiased dark:bg-zinc-900;
   }
   #root{
      @apply w-full;
   }
 }
@tailwind utilities;