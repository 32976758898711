.react-datepicker__close-icon::after {
   background-color: transparent !important;
   outline: none;
   color: #ccc;
   font-size: 20px;
   padding: 0;
   height: 24px;
   width: 24px;
   vertical-align: initial;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
   padding: 0.75rem 0.5rem;
}
.react-datepicker__day--outside-month {
   visibility: hidden;
}