.itm-popover{
   box-shadow: 0 5px 10px rgb(0 0 0 / 20%)
}
.itm-popover>.itm-arrow, .itm-popover>.itm-arrow:after{
   position: absolute;
   display: block;
   width: 0;
   height: 0;
   border-color: transparent;
   border-style: solid;
}
.itm-popover>.itm-arrow{
   border-width: 10px;
}
.itm-popover>.itm-arrow:after {
   content: "";
   border-width: 10px;
}
.itm-popover.bottom>.itm-arrow {
   top: -10px;
   left: 50%;
   margin-left: -11px;
   border-top-width: 0;
   border-bottom-color: #ddd;
   border-bottom-color: rgba(0,0,0,.05);
}
.itm-popover.bottom>.itm-arrow:after {
   top: 1px;
   margin-left: -10px;
   content: " ";
   border-top-width: 0;
   border-bottom-color: #fff;
}
.itm-popover.top>.itm-arrow {
   bottom: -10px;
   left: 50%;
   margin-left: -11px;
   border-bottom-width: 0;
   border-top-color: #ddd;
   border-top-color: rgba(0,0,0,.05);
}
.itm-popover.top>.itm-arrow:after {
   bottom: 1px;
   margin-left: -10px;
   content: " ";
   border-bottom-width: 0;
   border-top-color: #fff;
}